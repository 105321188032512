import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const senior_scala_engineer = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Senior Scala Engineer. Apply to join our team!',
    },
    keywords: {
      keywords: 'Senior Scala Engineer,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/9NT4aSHWpARX3Had9L7kO/63b95844389743ad6918ae053894010f/senior-scala-engineer.jpg',
      },
    },
    title: {
      title: 'Senior Scala Engineer',
    },
  }

  const link = 'https://apply.workable.com/lensesio/j/BF94C4087D/'

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Senior Scala Engineer</h1>
          <p className="meta">London, Munich, Athens, Madrid </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>

            <ul className="pl-4 ">
              <li>
                Work in an agile environment to deliver robust quality products
                release after release
              </li>
              <li>Champion best practices within the Scala chapter</li>
              <li>Participate in product initiatives and decisions</li>
              <li>
                Design, implement and own the technical approach for new product
                initiatives
              </li>
              <li>
                Spike new ideas or new approaches to improve existing
                functionality and code base
              </li>
              <li>Investigate and reproduce bugs reported by customers</li>
              <li>
                Interact with our customers, as part of 2nd level support rota,
                to gather feedback and improve our product
              </li>
              <li>
                Help upskill and mentor existing team members through coaching
                and pair programming
              </li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                Enjoys working in a collaborative atmosphere where new ideas are
                valued
              </li>
              <li>
                Self-starter who is motivated to deliver and improve knowledge
                constantly
              </li>
              <li>
                Enjoys writing code in a functional programming style but also
                has appreciation for other paradigms: most of our business logic
                is written using cats-effect, fs2 and other Typelevel libraries,
                but we heavily integrate with traditional java APIs
              </li>
              <li>
                Experience using Docker, Kubernetes, clouds (ie. GCE, AWS,
                Azure)
              </li>
              <li>
                Experience using RDBMS databases. Any other storage solutions
                like Cassandra, MongoDB, InfluxDb it isa plus
              </li>
              <li>
                Experience using Messaging Systems (Apache Kafka in particular,
                Apache Pulsar, JMS)
              </li>
              <li>
                Experience in writing understandable, testable and maintainable
                code
              </li>
              <li>Experience using Lucene will be an advantage</li>
              <li>
                Experience as an Open Source Contributor and/or Speaker in the
                Streaming will be a huge asset
              </li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default senior_scala_engineer
